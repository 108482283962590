/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    code: "code",
    pre: "pre",
    strong: "strong",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "function-to-convert-vector-of-characters-to-string",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#function-to-convert-vector-of-characters-to-string",
    "aria-label": "function to convert vector of characters to string permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Function to convert Vector of characters to String"), "\n", React.createElement(_components.p, null, "for competitive programming and more in Rust"), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, "In the previous article, we saw that how can we ", React.createElement(_components.a, {
    href: "/basic-programs/string-to-vector/"
  }, "convert a string to vector of characters in Rust"), " . Now, we must be able to convert the vector back to string for various purposes like for output in CP."), "\n", React.createElement(_components.p, null, "So, in this article, we will see How to convert vector of characters to strings in Rust, and build a function for the same."), "\n", React.createElement(_components.p, null, "This article is going to be rather small because it is just 1 line function."), "\n", React.createElement(_components.h2, {
    id: "convert-vector-of-char-to-string",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#convert-vector-of-char-to-string",
    "aria-label": "convert vector of char to string permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Convert Vector of ", React.createElement(_components.code, null, "char"), " to String"), "\n", React.createElement(_components.p, null, "We can simply use ", React.createElement(_components.code, null, "collect::<String>()"), " function to convert Vector of chars to the string. Here is function for this approach"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn to_string(vec:Vec<char>) -> String{\n    return vec.iter().collect::<String>();\n}\n")), "\n", React.createElement(_components.p, null, "With Driver code, this looks like"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "// For converting vector to string\nfn to_string(vec:Vec<char>) -> String{\n    return vec.iter().collect::<String>();\n}\n\n// Driver code\n\nfn main() {\n    let str1 = vec!['H', 'e', 'l', 'l', 'o'];\n    println!(\"{}\", to_string(str1));\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Hello"), "\n"), "\n", React.createElement(_components.p, null, "In the above code, ", React.createElement(_components.code, null, ".iter()"), " is used to traverse through the vector. See ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/stable/std/iter/#the-three-forms-of-iteration"
  }, "Iterator in Rust Documentation")), "\n", React.createElement(_components.p, null, "And  ", React.createElement(_components.code, null, "collect::<String>()"), "  function is used to convert an iterator into a String."), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "For many applications as well as competitive programming output, it is very handy to have a function to convert a vector to a string, especially for showing output. In this article, we made such a function using ", React.createElement(_components.code, null, "iter().collect()"), " method in Rust."), "\n", React.createElement(_components.p, null, "Here is function for easy access"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn to_string(vec:Vec<char>) -> String{return vec.iter().collect::<String>();}\n")), "\n", React.createElement(_components.p, null, "You might want to add this function to your template, if you are doing competitive programming in rust."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Function to convert Vector of characters to String - Rust Programming",
    description: "For many applications and competitive programming output, it is very handy to have a function to convert a vector to a string. We make such a function here using iter().collect() method in Rust."
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
